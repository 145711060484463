import axios from 'axios'
import qs from 'qs'
import sourceMaterial from "@/views/system/sourceMaterial";
import {MessageBox} from "element-ui";
import router from '/src/router'
axios.defaults.timeout = 200000;
export let isRelogin = { show: false };
// axios.defaults.baseURL = process.env.VUE_APP_API_URL + "/";
// const baseURL = 'https://rl.hbyouke.com/prod-api/'; //正式版
const baseURL = 'https://admin.relaqidu.cn/prod-api/'; //正式版 正式域名
// const baseURL = 'http://192.168.1.116:8080/'; //本地
// const baseURL = 'https://cccc.hbyouke.com/prod-api/'; //测试版
// const baseURL = 'http://192.168.1.116:8080/';
// const baseURL = process.env.VUE_APP_API_URL;
// post
export function postApi(url, data, configs) {
	// var configs = {
	// headers: {
	// 	'Content-Type': 'application/json;charset=UTF-8',
	// 	Authorization: localStorage.getItem('token')
	// }
	// };
	return axios.post(baseURL + url, data, configs)
		.then(response => {
			switch (response.data.code) {
				case 401:
					if (!isRelogin.show) {
						isRelogin.show = true;
						MessageBox.confirm('登录状态已失效，请重新登录', '提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
							isRelogin.show = false;
							localStorage.clear();
							router.replace('/')
						}).catch(() => {
							isRelogin.show = false;
						});
					}
					return
					break;
			}
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
// put
export function putApi(url, data, configs) {
	return axios.put(baseURL + url, data, configs)
		.then(response => {
			switch (response.data.code) {
				case 401:
					if (!isRelogin.show) {
						isRelogin.show = true;
						MessageBox.confirm('登录状态已失效，请重新登录', '提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
							isRelogin.show = false;
							localStorage.clear();
							router.replace('/')
						}).catch(() => {
							isRelogin.show = false;
						});
					}
					return
					break;
			}
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
//get
export function getApi(url, params) {
	return axios.get(baseURL + url, params)
		.then(response => {
			switch (response.data.code) {
				case 401:
					if (!isRelogin.show) {
						isRelogin.show = true;
						MessageBox.confirm('登录状态已失效，请重新登录', '提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
							isRelogin.show = false;
							localStorage.clear();
							router.replace('/')
						}).catch(() => {
							isRelogin.show = false;
						});
					}
					return
					break;
			}
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
//delete
export function delApi(url, params) {
	return axios.delete(baseURL + url, params)
		.then(response => {
			switch (response.data.code) {
				case 401:
					if (!isRelogin.show) {
						isRelogin.show = true;
						MessageBox.confirm('登录状态已失效，请重新登录', '提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
							isRelogin.show = false;
							localStorage.clear();
							router.replace('/')
						}).catch(() => {
							isRelogin.show = false;
						});
					}
					return
					break;
			}
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
/**
 * 使用Axios下载文件流
 * @param {String} url - 请求的URL
 * @param {String} [fileName] - 下载文件的名称，可选
 */


export async function downloadFile(tar_url, tar_method, fileName) {
	try {
		// 发起请求
		const response = await axios({
			url: process.env.VUE_APP_API_URL + '/' + tar_url,
			method: tar_method,
			responseType: 'blob', // 告诉axios我们期望得到一个blob响应
			headers: {
				'Content-Type': 'application/json',
				Authorization: localStorage.getItem('token') || ''
			},
		});
		const reader = new FileReader();
		reader.readAsText(response.data);
		reader.onload = function(event) {
			const content = event.target.result; // 读取到的内容
			try {
				// 将内容解析为JSON对象
				const jsonObject = JSON.parse(content);
				console.log(jsonObject); // 输出JSON对象
				if(jsonObject.code === 401){
					if (!isRelogin.show) {
						isRelogin.show = true;
						MessageBox.confirm('登录状态已失效，请重新登录', '提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
							isRelogin.show = false;
							localStorage.clear();
							router.replace('/')
						}).catch(() => {
							isRelogin.show = false;
						});
					}
					return;
				}
			} catch (error) {
				console.error('解析JSON失败:', error);
			}
			// 创建一个指向该blob的URL
			const url = window.URL.createObjectURL(new Blob([response.data]));

			// 创建一个a标签用于下载
			const link = document.createElement('a');
			link.href = url;
			// 设置下载的文件名，这里需要后端在响应头中提供文件名，或者前端固定文件名
			link.setAttribute('download', fileName); // 假设是PDF文件
			document.body.appendChild(link);
			link.click();

			// 清理工作
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		};
	} catch (error) {
		console.error('下载文件出错:', error);
	}
}
