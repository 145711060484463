<template>
	<div class="menus-info">
		<div class="menus">
			<el-col>
				<el-menu :default-active="menusIdx" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose" @select="handleSelect" background-color="#0069aa" text-color="#fff"
					active-text-color="#0069aa" style="width: 100% !important;height: 100vh" :router="true">
					<div class="logo-box">
						<img class="logo" src="../../assets/menus/logo.png" />
					</div>
					<el-menu-item :index="index.toString()" v-for="(item, index) in menusInfo" :key="index"
						:route="item.path">
						<!-- <i class="el-icon-menu"></i> -->
						<img v-if="menusIdx == index" :src="item.meta.icon[1]"
							:style="{ width: item.width, height: item.height }" />
						<img v-else :src="item.meta.icon[0]" :style="{ width: item.width, height: item.height }" />
						<span slot="title">{{ item.meta.title }}</span>
					</el-menu-item>
				</el-menu>
			</el-col>
		</div>
		<div class="bedding"></div>
	</div>
</template>
<script>
	export default {
		props: {
			menusIndex: {
				typeof: String,
				default: '0'
			}
		},
		data() {
			return {
				menusIdx: 0, //菜单下标
				// menusInfo: [{
				// 		name: "首页",
				// 		img: require("../../assets/menus/home.png"),
				// 		imgAction: require("../../assets/menus/home-active.png"),
				// 		width: "17px",
				// 		height: "17px",
				// 		url: "/home"
				// 	},
				// 	{
				// 		name: "商品",
				// 		img: require("../../assets/menus/merchandise.png"),
				// 		imgAction: require("../../assets/menus/merchandise-active.png"),
				// 		width: "17px",
				// 		height: "19px",
				// 		url: "/merchandise"
				// 	},
				// 	{
				// 		name: "订单",
				// 		img: require("../../assets/menus/order.png"),
				// 		imgAction: require("../../assets/menus/order-active.png"),
				// 		width: "17px",
				// 		height: "19px",
				// 		url: "/order"
				// 	},

				// 	{
				// 		name: "用户管理",
				// 		img: require("../../assets/menus/user.png"),
				// 		imgAction: require("../../assets/menus/user-active.png"),
				// 		width: "16px",
				// 		height: "19px",
				// 		url: "/userInfo"
				// 	},
				// 	{
				// 		name: "营销",
				// 		img: require("../../assets/menus/merchandise.png"),
				// 		imgAction: require("../../assets/menus/merchandise-active.png"),
				// 		width: "21px",
				// 		height: "18px",
				// 		url: "/marketing"
				// 	},
				// 	{
				// 		name: "财务",
				// 		img: require("../../assets/menus/finance.png"),
				// 		imgAction: require("../../assets/menus/finance-active.png"),
				// 		width: "21px",
				// 		height: "19px",
				// 		url: "/finance"
				// 	},
				// 	{
				// 		name: "日志",
				// 		img: require("../../assets/menus/log.png"),
				// 		imgAction: require("../../assets/menus/log-active.png"),
				// 		width: "17px",
				// 		height: "21px",
				// 		url: "/log"
				// 	},
				// 	{
				// 		name: "系统",
				// 		img: require("../../assets/menus/system.png"),
				// 		imgAction: require("../../assets/menus/system-active.png"),
				// 		width: "21px",
				// 		height: "22px",
				// 		url: "/system"
				// 	}
				// ],
				menusInfo: []
			};
		},
		created() {
			// this.menusIdx = this.menusIndex;
			if(!localStorage.getItem('token')) {
				this.$router.push('/');
			}
			this.menusIdx = localStorage.getItem('menusIdx');
			this.menusInfo = JSON.parse(localStorage.getItem('routers'))
			var tokenState = localStorage.getItem('tokenState')
			if (tokenState == 1) {
				var lerar = setTimeout(() => {
					this.menusInfo = JSON.parse(localStorage.getItem('routers'))
					// console.log(this.menusInfo,'定时器');
					this.menusInfo.forEach((item, index) => {
						item.meta.icon = item.meta.icon.split(',')
						return
					})
					localStorage.removeItem('tokenState')
				}, 500);
			} else {
				this.menusInfo = JSON.parse(localStorage.getItem('routers'))
				this.menusInfo.forEach((item, index) => {
					item.meta.icon = item.meta.icon.split(',')
					return
				})
			}
			
			

		},
		methods: {
			handleSelect(index) {
				// console.log(index);
				this.menusIdx = index;
				localStorage.setItem('children', JSON.stringify(this.menusInfo[index].children));
				localStorage.setItem('menusName', JSON.stringify(this.menusInfo[index].meta.title));
				localStorage.setItem('menusIndex', 0);
				localStorage.setItem('menusIdx', index);
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			
		},
	};
</script>
<style scoped>
	.menus-info {
		width: 77px;
		height: auto;
		display: flex;
	}

	.menus {
		width: 77px;
		min-height: 100vh;
		background-color: #0069aa;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index:999;
	}

	.logo-box {
		widows: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logo {
		width: 59px;
		height: 59px;
	}

	.el-menu {
		border: none !important;
	}

	.el-menu-item {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.el-menu-item img {
		width: 15px;
		height: 15px;
	}

	.el-menu-item span {
		line-height: 25px;
	}

	.el-menu-item.is-active {
		background-color: #fff !important;
	}

	.bedding {
		width: 77px;
		min-height: 100vh;
	}
</style>