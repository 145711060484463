<template>
	<div class="nav">
		<!-- <img src="../../assets/image/zoom.png" /> -->
		<img src="../../assets/image/on-off.png" @click="layout" />
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			layout() {
				this.$confirm('是否退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear();
					this.$router.replace('/')
					this.$message({
						type: 'success',
						message: '退出成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消退出'
					});
				});
			}
		},
	};
</script>

<style scoped lang="less">
	.nav {
		width: 100%;
		height: 51px;
		background: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		

		img:first-of-type {
			width: 16px;
			height: 16px;
			margin-left:auto;
			margin-right: 40px;
		}
	}
</style>