<template>
	<div class="menus-info">
		<div class="menus">
			<el-col>
				<el-menu :default-active="menusIndex" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose" @select="handleSelect" background-color="#fff" text-color="#000"
					active-text-color="#fff" style="height: 100vh" :router="false" :collapse-transition="false">
					<div class="title">{{menusName}}</div>
					<el-menu-item :index="index.toString()" v-for="(item, index) in menusInfo" :key="index"
						:route="item.path">
						<span slot="title">{{ item.meta.title }}</span>
					</el-menu-item>
				</el-menu>
			</el-col>
		</div>
		<div class="bedding"></div>
	</div>
</template>
<script>
	export default {
		props: {
			menusNum: {
				typeof: String,
				default: '0'
			}
		},
		data() {
			return {
				menusIndex: '0', //菜单下标
				menusInfo: [],
				menusName: ''
			};
		},
		created() {
			console.log(this.menusNum);
			// this.menusIndex = this.menusNum;
			this.menusIndex = localStorage.getItem('menusIndex');
			this.menusInfo = JSON.parse(localStorage.getItem('children'))
			this.menusName = JSON.parse(localStorage.getItem('menusName'))
		},
		methods: {
			handleSelect(index) {
				console.log(index);
				this.menusIndex = String(index);
				localStorage.setItem('menusIndex', index);
				const targetPath = this.menusInfo[this.menusIndex].path
				const currentPath = this.$route.path
				
				if(currentPath != targetPath){
					this.$router.push(this.menusInfo[this.menusIndex].path);
				} else {
					this.$router.go(0);
				}
				// console.log(this.$route.path,'this.$route.path')
				// this.$router.push(this.menusInfo[this.menusIndex].path);
				// if(index == this.menusIndex){
				// 	console.log(this.menusInfo[this.menusIndex].path,'111111111')
				// 	this.$router.push(this.menusInfo[this.menusIndex].path);
				// }else{
				// 	this.menusIndex = String(index);
				// 	localStorage.setItem('menusIndex', index);
				// 	this.$router.push(this.menusInfo[this.menusIndex].path);
				// }
				
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
		},
	};
</script>
<style scoped>
	.menus-info {
		width: 123px;
		height: auto;
	}

	.menus {
		width: 123px;
		min-height: 100vh;
		background-color: #fff;
		position: fixed;
		top: 0px;
		left: 77px;
		z-index:999;
	}

	.el-menu {
		border: none !important;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.title {
		font-family: AlibabaPuHuiTi_2_65_Medium;
		font-size: 20px;
		color: #131313;
		line-height: 20px;
		margin: 20px 0px 20px 0px;
	}

	.el-menu-item {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.el-menu-item.is-active {
		width: 109px;
		height: 43px;
		border-radius: 8px 8px 8px 8px;
		background-color: #0069aa !important;
		margin: 0px auto;
	}

	.bedding {
		width: 123px;
		min-height: 100vh;
	}

	/* /deep/.el-menu-item:hover {
	    border-color: inherit;
	    background-color: inherit !important;
	} */
</style>