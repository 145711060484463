<template>
	<div class="index">
		<!-- 菜单栏 -->
		<menus :menusIndex="menusIndex"></menus>
		<menus-item :menusNum="menusNum"></menus-item>
		<!-- 右侧内容 -->
		<div class="content">
			<page-header></page-header>
			<div class="main">
				<div class="tab-info">
					<div :class="tabIndex==1?'tab-active':'tab'" @click="onTab(1)">
						<span>图片</span>
						<p></p>
					</div>
					<div :class="tabIndex==2?'tab-active':'tab'" class="tab" @click="onTab(2)">
						<span>视频</span>
						<p></p>
					</div>
				</div>
				<div class="boxes">
					<div class="left">
						<div class="list">
							<!-- 	<div class="list-li" :class="{'list-li-active': item.id == categoryId}"
								@click="onCategory(item)" v-for="(item,index) in categoryList" :key="index">
								<div class="categoryname">{{item.categoryName}}</div>
								<div v-if="item.editChecked  && index !=0" class="categoryname"><el-input
										v-model="item.categoryName"></el-input></div>
								<i v-if="item.editChecked  && index !=0" class="el-icon-success"
									@click.stop="saveEditCategory(index)"></i>
								<i v-if="item.editChecked  && index !=0" class="el-icon-error"
									@click.stop="cancleditCategory(index)"></i>
								<i v-if="index !=0" class="el-icon-edit" @click.stop="editCategory(index)"></i>
								<i v-if="index !=0" class="el-icon-delete" @click.stop="delCategory(item.id)"></i>
							</div> -->
							<div class="list-li">
								<el-tree :data="categoryList" node-key="id" :expand-on-click-node="false">
									<div class="custom-tree-node" slot-scope="{ node, data }" @click="onCategory(data)">
										<p>{{data.categoryName}}</p>
										<el-button v-if="data.id !=0" type="text" size="mini"
											@click.stop="() => onOpen(data.id)" v-show="hasPermission('shopping:sdMaterialCategory:add')">
											新增
										</el-button>
										<el-button v-if="data.id !=0" type="text" size="mini"
											@click.stop="() => onUpd(data)" v-show="hasPermission('shopping:sdMaterialCategory:edit')">
											编辑
										</el-button>
										<el-button v-if="data.id !=0" type="text" size="mini"
											@click.stop="delCategory(data.id)" v-show="hasPermission('shopping:sdMaterialCategory:remove')">
											删除
										</el-button>
									</div>
								</el-tree>
							</div>
						</div>
					</div>
					<div class="details">
						<div class="title">
							<div class="title-button" @click="imgUploadVisible = true" v-show="hasPermission('shopping:sdMaterial:add')">上传{{tabIndex == 1 ? '图片' :'视频'}}
							</div>
							<div class="title-button" @click="categoryVisible = true" v-show="hasPermission('shopping:sdMaterialCategory:add')">添加分类</div>
						</div>
						<div class="main">
							<div class="img" v-for="(item,index) in list" :key="index">
								<div class="delImgs">
									<el-image v-if="item.fileType == 1" :preview-src-list="imageArr"
										style="width: 150px; height: 150px" :src="item.materialUrl" fit="cover">
									</el-image>
									<video v-if="item.fileType == 2" style="width: 150px; height: 150px"
										:src="item.materialUrl" controls></video>
									<div class="delInfo" v-show="hasPermission('shopping:sdMaterial:remove')">
										<div class="delImg" @click="delImg(item.id)">
											<img src="@/assets/image/close.png" />
											<span>删除</span>
										</div>
										<div class="move" @click="onMove(item.id)" v-show="hasPermission('shopping:sdMaterial:edit')">
											<img src="@/assets/image/move.png" />
											<span>挪动</span>
										</div>
									</div>
								</div>
								<div class="img-title">{{item.materialName}}</div>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
							@size-change="handleSizeChange" @current-change="handleCurrentChange">
						</el-pagination>
					</div>
				</div>
			</div>

		</div>
		<el-dialog :title="!classifyInfo?'添加分类':'编辑分类'" :visible.sync="categoryVisible" width="30%"
			:before-close="handleClose">
			<div class="addcategory">
				<div class="category-li">
					<div class="category-name" style="margin-bottom: 10px;">分类名称</div>
					<div class="category-input"><el-input v-model="newCategoryName" placeholder="请输入分类名称"></el-input>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="categoryVisible = false">取 消</el-button>
				<el-button v-if="!classifyInfo" type="primary" @click="onSubmitCategory">确 定</el-button>
				<el-button v-if="classifyInfo" type="primary" @click="saveEditCategory">编 辑</el-button>
			</span>
		</el-dialog>
		<el-dialog title="添加素材" :visible.sync="imgUploadVisible" width="35%" :before-close="handleClose">
			<div class="addcategory">
				<div class="category-li">
					<el-upload class="avatar-uploader" :action="uploadImgUrl" :headers="headerOdj"
						:show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :accept="upaccept">
						<img v-if="is_image && uploadImage" :src="uploadImage" class="avatar">
						<video v-else-if="!is_image && uploadImage" :src="uploadImage" class="avatar" controls></video>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
          <span v-show="tabIndex == 1">图片素材不得超过2MB</span>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="imgUploadVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmitImg">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="挪动图片" :visible.sync="isShow" width="35%" :before-close="handleClose">
			<div class="addcategory">
				<div class="list-li">
					<el-tree :data="categoryList" node-key="id" :expand-on-click-node="false">
						<div class="custom-tree-node" style="width: 100%;height: auto;" slot-scope="{ node, data }"
							@click="choose(data)">
							<p>{{data.categoryName}}</p>
						</div>
					</el-tree>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="isShow = false">取 消</el-button>
				<el-button type="primary" @click="onConfirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import menus from "@/components/menus/menus.vue";
	import menusItem from '@/components/menus/menus-item.vue';
	import pageHeader from '@/components/menus/page-header.vue';
	export default {
		components: {
			menus,
			menusItem,
			pageHeader,
		},
		data() {
			return {
				menusIndex: '7',
				menusNum: '10',
				input: '',
				tabIndex: 1,
				categoryList: [],
				categoryId: 0,
				pageNum: 1,
				pageSize: 16,
				total: 0,
				list: [],
				categoryVisible: false,
				newCategoryName: '',
				imgUploadVisible: false,
				fileList: [],
				headerOdj: {
					Authorization: localStorage.getItem('token')
				},
				uploadImgUrl: process.env.VUE_APP_API_URL + "/common/upload",
				// uploadImgUrl: "http://192.168.1.116:8080/common/upload", //本地
				uploadImage: '',
				uploadImgaeName: '',
				is_image: true,
				imageArr: [],
				upaccept: '.png, .jpg, .jpeg',
				parentId: 0,
				classifyInfo: null,
				isShow: false,
				chooseId: null, //素材分类id
				moveId: null, ////素材id
        permissions: []
			}
		},
		mounted() {
      this.permissions = JSON.parse(localStorage.getItem("routersInfo"));
			this.getsdMaterialCategory();
			this.getsdMaterial();
		},
		methods: {
      hasPermission(val) {
        if (this.permissions.includes('*:*:*')) {
          return true;
        } else if (this.permissions.includes(val)) {
          return true;
        } else {
          return false;
        }
      },
			onConfirm() {
				this.$http.putApi('shopping/sdMaterial', {
					id: this.moveId,
					categoryId: this.chooseId
				}, {
					headers: {
						'Content-Type': 'application/json;charset=UTF-8',
						Authorization: localStorage.getItem('token') || ''
					}
				}).then(res => {
					if (res.code == 200) {
						this.chooseId=null;
						this.moveId=null;
						this.isShow=false;
						this.getsdMaterial();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				}).catch(res => {});
			},
			onMove(val) {
				this.moveId = val;
				this.isShow = true;
			},
			choose(val) {
				this.chooseId = val.id;
				console.log(val, '11112');
			},
			onOpen(val) {
				this.parentId = val;
				this.categoryVisible = true;
			},
			onUpd(val) {
				this.classifyInfo = val;
				this.newCategoryName = val.categoryName;
				this.categoryVisible = true;
			},
			delImg(val) {
				var that = this;
				var tips = '';
				if (this.tabIndex == 1) {
					tips = '您确定要删除这张图片吗?'
				} else if (this.tabIndex == 2) {
					tips = '您确定要删除这个视频吗?'
				}
				that.$confirm(tips, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$http.delApi('shopping/sdMaterial/' + val, {
						headers: {
							"Content-Type": "application/json;charset=UTF-8",
							Authorization: localStorage.getItem("token"),
						},
					}).then((res) => {
						console.log(res)
						if (res.code == 200) {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.getsdMaterial();
						} else {
							that.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch((res) => {})
				}).catch(() => {
					that.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
      beforeAvatarUpload(file) {
        if (this.tabIndex == 1 && file.size / 1024 / 1024 > 2) {
          this.$message.error("图片素材不得超过2MB");
          return false;
        }
      },
			handleAvatarSuccess(res, file) {
				console.log(res, file);
				this.uploadImage = res.url;
				this.uploadImgaeName = res.newFileName;
				if (file.raw.type == 'video/mp4' || file.raw.type == 'video/ogg' || file.raw.type == 'video/webm') {
					this.is_image = false
				} else {
					this.is_image = true
				}
				// this.classifyImage = res.url;
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			onTab(val) {
				if (val == 1) {
					this.upaccept = '.png, .jpg, .jpeg'
				} else {
					this.upaccept = '.mp4'
				}
				this.tabIndex = val
				this.uploadImage = '',
					this.uploadImgaeName = '',
					this.getsdMaterialCategory()
			},
			onCategory(item) {
				this.categoryId = item.id
				this.getsdMaterial()
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getsdMaterial();
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getsdMaterial();
			},
			// 分类列表
			getsdMaterialCategory() {
				this.$http.getApi('shopping/sdMaterialCategory/treeList/' + this.tabIndex, {
					headers: {
						Authorization: localStorage.getItem("token") || "",
					},
				}).then((res) => {
					if (res.code == 200) {
						res.data.unshift({
							categoryName: "全部",
							createBy: "",
							createTime: "",
							id: 0,
							type: "1",
						})
						res.data.forEach((item) => {
							item.editChecked = false
						})
						this.categoryList = res.data
						this.getsdMaterial()
					}
				}).catch((res) => {})
			},
			getsdMaterial() {
				var params = ''
				params = '?type=' + this.tabIndex + '&categoryId=' + this.categoryId
				this.$http.getApi('shopping/sdMaterial/list' + params, {
					params: {
						pageNum: this.pageNum,
						pageSize: this.pageSize
					},
					headers: {
						Authorization: localStorage.getItem("token") || "",
					},
				}).then((res) => {
					if (res.code == 200) {
						var imageArr = []
						res.rows.forEach((item) => {
							if (item.materialName.indexOf('mp4') != -1 || item.materialName.indexOf(
									'ogg') != -1 || item.materialName.indexOf('webm') != -1) {
								item.fileType = 2
							} else {
								item.fileType = 1
								imageArr.push(item.materialUrl)
							}
						})
						this.imageArr = imageArr
						this.list = res.rows
						this.total = res.total
						// this.categoryList = res.data
					}
				}).catch((res) => {})
			},
			// 添加分类
			onSubmitCategory() {
				this.$http.postApi('shopping/sdMaterialCategory', {
					parentId: this.parentId ? this.parentId : 0,
					type: this.tabIndex,
					categoryName: this.newCategoryName
				}, {
					headers: {
						"Content-Type": "application/json;charset=UTF-8",
						Authorization: localStorage.getItem("token"),
					},
				}).then((res) => {
					console.log(res)
					if (res.code == 200) {
						this.parentId = 0;
						this.newCategoryName = '';
						this.categoryVisible = false;
						this.getsdMaterialCategory();
					} else {
						this.$message.error(res.msg);
					}
				}).catch((res) => {})
			},
			editCategory(index) {
				this.categoryList[index].editChecked = true
			},
			saveEditCategory() {
				var editCategory = this.classifyInfo;
				var params = '?id=' + editCategory.id + '&categoryName=' + this.newCategoryName + '&parentId=' +
					editCategory.parentId
				this.$http.putApi('shopping/sdMaterialCategory' + params, {}, {
					headers: {
						'Content-Type': 'application/json;charset=UTF-8',
						Authorization: localStorage.getItem('token') || ''
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message.success(res.msg);
						this.classifyInfo = null;
						this.newCategoryName = '';
						this.categoryVisible = false;
						this.getsdMaterialCategory();
					} else {
						this.$message.error(res.msg);
					}
				}).catch(res => {});
			},
			cancleditCategory(index) {
				this.categoryList[index].editChecked = false
			},
			//删除分类
			delCategory(id) {
				var that = this;
				that.$confirm('您确定要删除这此分类吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$http.delApi('shopping/sdMaterialCategory/' + id, {
						headers: {
							"Content-Type": "application/json;charset=UTF-8",
							Authorization: localStorage.getItem("token"),
						},
					}).then((res) => {
						console.log(res)
						if (res.code == 200) {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.getsdMaterialCategory();
						} else {
							that.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch((res) => {})
				}).catch(() => {
					that.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 上传图片
			onSubmitImg() {
				if (this.uploadImage == '') {
					this.$message({
						type: 'info',
						message: '图片不能为空'
					});
					return
				}
				this.$http.postApi('shopping/sdMaterial', {
					type: this.tabIndex,
					categoryId: this.categoryId,
					materialUrl: this.uploadImage,
					materialName: this.uploadImgaeName
				}, {
					headers: {
						"Content-Type": "application/json;charset=UTF-8",
						Authorization: localStorage.getItem("token"),
					},
				}).then((res) => {
					console.log(res)
					if (res.code == 200) {
						this.uploadImage = '';
						this.uploadImgaeName = '';
						this.imgUploadVisible = false;
						this.getsdMaterial();
					}
				}).catch((res) => {})
			}
		},
	};
</script>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
<style scoped lang="less">
	.index {
		width: 100%;
		height: auto;
		display: flex;

		.content {
			flex: 1;
			height: auto;
			margin-left: 2px;

			.main {
				margin: 20px;
				border-radius: 6px;
				height: auto;
				background: #FFFFFF;

				.tab-info {
					width: 100%;
					height: 50px;
					background-color: #fff;
					display: flex;
					border-bottom: 1px solid #ccc;

					.tab-active {
						width: 60px;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						margin-left: 20px;
						position: relative;
						cursor: pointer;

						span {
							font-family: AlibabaPuHuiTi_2_55_Regular;
							font-size: 15px;
							color: #0069A9;
						}

						p {
							width: 100%;
							height: 2px;
							background-color: #0069A9;
							position: absolute;
							bottom: 0px;
						}
					}

					.tab {
						width: 60px;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						margin-left: 20px;
						position: relative;
						cursor: pointer;

						span {
							font-family: AlibabaPuHuiTi_2_55_Regular;
							font-size: 15px;
							color: #131313;
						}

						p {
							width: 100%;
							height: 2px;
							position: absolute;
							bottom: 0px;
						}
					}
				}

				.boxes {
					flex: 1;
					height: auto;
					background: #FFFFFF;
					// border-radius: 10px;
					margin: 0px;
					display: flex;

					.left {
						border-right: 1px solid #ccc;
						margin: 10px 10px 10px 0;

						.list {
							width: 250px;

							.list-li {
								padding: 10px 0px;
								display: flex;
								justify-content: flex-start;
								align-items: center;

								.el-icon-edit {
									margin-left: auto;
								}

								.el-icon-delete {
									margin-left: 10px;
								}

								.custom-tree-node {
									flex: 1;
									height: auto;
									display: flex;
									align-items: center;
									font-family: AlibabaPuHuiTi_2_55_Regular;
									font-size: 12px;
									color: #131313;

									p:nth-child(1) {
										width: 90px;
									}
								}
							}

							.list-li-active {
								color: #0069A9;
							}
						}
					}

					.details {
						width: 100%;
						margin-bottom: 10px;
						margin-right: 10px;

						.title {
							display: flex;
							margin-top: 10px;

							.title-button {
								background: #0269aa;
								color: #fff;
								padding: 5px 15px;
								border-radius: 2px;
								margin-left: 10px;
							}
						}

						.main {
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-start;
							align-items: center;

							.img {
								width: 150px;
								margin: 10px;

								.delImgs {
									width: 150px;
									height: auto;
									position: relative;

									.el-image {
										background-color: #f6f4ff !important;
									}

									.delInfo {
										width: 100%;
										height: 20rpx;
										display: flex;
										align-items: center;
										background-color: rgba(0, 0, 0, 0.5);
										position: absolute;
										bottom: 0px;
										left: 0px;

										.delImg {
											flex: 1;
											height: 20px;
											display: flex;
											align-items: center;
											justify-content: center;

											img {
												width: 16px;
												height: 16px;
											}

											span {
												font-size: 15px;
												line-height: 15px;
												margin-left: 5px;
												color: #fff;
											}
										}

										.move {
											flex: 1;
											height: 20px;
											display: flex;
											align-items: center;
											justify-content: center;

											img {
												width: 16px;
												height: 16px;
											}

											span {
												font-size: 15px;
												line-height: 15px;
												margin-left: 5px;
												color: #fff;
											}
										}
									}
								}

								.img-title {
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									color: #8b8b8b;
								}
							}
						}

						.el-pagination {
							text-align: right;
						}
					}
				}
			}

		}


	}
</style>
